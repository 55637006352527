<script>
  import Layout from "../layouts/auth";
  import Repository from "../../app/repository/repository-factory";
  import Vue from "vue";
  import {minLength, required, sameAs} from "vuelidate/lib/validators";

  const UserAccountRepository = Repository.get("UserAccountRepository");

export default {
  components: { Layout },
  data() {
    return {
      preloader: true,
      submitted: false,
      form: {
        newPassword1: '',
        newPassword2: '',
      },
      iconPath: require(process.env.VUE_APP_ICON_PATH),
      orgName: process.env.VUE_APP_ORG_NAME,
    }
  },
  validations: {
    form: {
      newPassword1: {required, minLength: minLength(12) },
      newPassword2: {required, minLength: minLength(12), sameAsPassword1: sameAs('newPassword1') },
    }
  },
  methods: {
    changePassword() {
      this.formSubmit();

      if (this.$v.form.$error === true) {
        return false;
      }

      UserAccountRepository.changePasswordWithToken(this.form, this.$route.params.token).then(() => {

        Vue.swal({
          icon: "success",
          toast: false,
          position: 'top',
          title: "SUKCES! ",
          text: 'Hasło zostało zmienione pomyślnie! Zaloguj się używając nowego hasła.',
          showConfirmButton: false,
          timer: 3500,
          onClose: () => {
            this.submitted = false;
            this.form.newPassword1 = '';
            this.form.newPassword2 = '';
            this.$router.push({ name: 'Logowanie' })
          }
        });
      }).catch(error => {
        Vue.swal({
          icon: "error",
          position: 'top',
          title: "Wystąpił błąd. Spróbuj jeszcze raz!",
          text: error.response.data.message,
          showConfirmButton: true
        });
      })
    },
    formSubmit() {
      this.submitted = true;
      this.$v.form.$touch()
    },
  }
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">Zmiana hasła - krok 2</h5>
                  <p>Wpisz nowe hasło, którym będziesz mógł zalogować się do aplikacji.</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img src="@/assets/images/profile-img.png" alt class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <router-link tag="a" to="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-white">
                    <img :src="iconPath" alt height="52" />
                  </span>
                </div>
              </router-link>
            </div>
            <div class="p-2">
              <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="form-group">
                  <label for="newPassword1">Nowe hasło</label>
                  <input
                          id="newPassword1"
                          v-model="form.newPassword1"
                          type="password"
                          class="form-control"
                          placeholder="Nowe hasło*"
                          :class="{ 'is-invalid': submitted && $v.form.newPassword1.$error }"
                  />
                  <div v-if="submitted && $v.form.newPassword1.$error"
                       class="invalid-feedback">
                    <span v-if="!$v.form.newPassword1.required">Ta wartość jest wymagana.<br></span>
                    <span v-if="!$v.form.newPassword1.minLength">Wartość musi zawierać minimum 12 znaków.<br></span>
                    <span v-if="!$v.form.newPassword2.sameAsPassword1">Hasła są różne.</span>
                  </div>
                </div>
                <div class="form-group">
                  <label for="newPassword1">Powtórz hasło</label>
                  <input
                          id="newPassword2"
                          v-model="form.newPassword2"
                          type="password"
                          class="form-control"
                          placeholder="Nowe hasło - powtórz*"
                          :class="{ 'is-invalid': submitted && $v.form.newPassword2.$error }"
                  />
                  <div v-if="submitted && $v.form.newPassword2.$error"
                       class="invalid-feedback">
                    <span v-if="!$v.form.newPassword2.required">Ta wartość jest wymagana.<br></span>
                    <span v-if="!$v.form.newPassword2.minLength">Wartość musi zawierać minimum 12 znaków.<br></span>
                    <span v-if="!$v.form.newPassword2.sameAsPassword1">Hasła są różne.</span>
                  </div>
                  <span class="mt-5 font-size-11">* Nowe hasło musi mieć długość minimum 12 znaków. <br>Najlepiej, jeżeli będzie składało się z małych i DUŻYCH liter oraz cyfr i znaków specjalnych. </span>
                </div>

                <div class="form-group row mb-0">
                  <div class="col-12 text-right">
                    <button class="btn btn-primary w-md" v-on:click="changePassword" type="submit">Zmień hasło</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">
          <p>
            Jednak pamiętasz swoje hasło?
            <router-link tag="a" to="/login" class="font-weight-medium text-primary">Zaloguj się</router-link>
          </p>
          <p>
           © {{new Date().getFullYear()}} Fundacja Zobacz Mnie!
            <i class="mdi mdi-heart text-danger"></i>
          </p>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style lang="scss" module></style>
